<!-- 在线分单 -->
<template>
  <div v-if="orderInfo">
    <van-cell-group>
      <van-cell title-class="cus-title" value-class="cus-value" :title="'订单号：' + orderInfo.order_id"
        :value="orderInfo.stateName" :border="false" />
    </van-cell-group>
    <van-cell-group>
      <div class="product-list">
        <van-cell class="good-item" v-for="(item, index) in productList" :key="index">
          <img :src="item.productUrl" />
          <div class="media-body">
            <div class="good-name ellipsis2">{{ item.productName }}</div>
            <!-- <van-tag type="primary" round color="#FF98A0">加价购</van-tag> -->
          </div>
          <div class="count">x{{ item.productCount }}</div>
        </van-cell>
        <van-cell title-class="large-title" title="搜索分单：" :border="false" />
        <van-cell class="field-cont">
          <van-field v-model="searchKey" placeholder="输入手机号、姓名搜索" />
          <van-button type="primary" color="#FF98A0" @click="onSearch(1)">搜索</van-button>
        </van-cell>
      </div>
    </van-cell-group>
    <van-cell-group>
      <van-cell title-class="large-title" title="筛选分单：" :border="false" />
      <van-cell class="filter-box" @click="showLevelPicker = true">
        <template v-if="chooseItem">{{ chooseItem.level_name }}</template>
        <span v-else>选择金星旗舰店/银星旗舰店</span>
        <van-icon name="arrow-down" color="#999" size="20" />
      </van-cell>
    </van-cell-group>
    <van-cell-group class="user-list">
      <template v-if="userList.length">
        <div class="van-cell user-item" v-for="(item, index) in userList" :key="index">
          <img :src="item.headimg || defaultHeadUrl" />
          <div class="user-cont">
            <div class="user-info">
              <div class="name ellipsis1">{{ item.agent_name }}</div>
              <div class="phone">
                <van-icon name="phone" color="#999" /><span>{{ item.agent_pho }}</span>
                <van-tag type="primary" color="#999" round v-clipboard:copy="item.agent_pho"
                  v-clipboard:success="copyBtn">复制</van-tag>
              </div>
            </div>
            <div class="level-name">
              {{ item.MzStr }} <template v-if="item.MzStr && item.MvStr">|</template> {{ item.MvStr }}
            </div>
          </div>
          <van-button type="primary" color="#FF98A0" round @click="handleSplit(item)">分给Ta</van-button>
        </div>
        <div class="load-more" v-if="!finished && !loading" @click="getUserList">点击加载更多</div>
      </template>
      <van-loading size="24px" v-if="loading">加载中...</van-loading>
      <div v-else-if="finished && !userList.length" class="no-data">暂无数据</div>
    </van-cell-group>
    <van-popup v-model="showLevelPicker" position="bottom">
      <van-picker :show-toolbar="true" :columns="columns" @confirm="onConfirm" value-key="level_name"
        @cancel="showLevelPicker = false" />
    </van-popup>
  </div>
</template>

<script>
export default {
  name: 'SplitOrderOnline',
  components: {},
  data() {
    return {
      orderId: 0,
      searchKey: '',
      chooseItem: '',
      productList: [],
      showLevelPicker: false,
      columns: [
        { text: '旗舰店(金星)', id: 4 },
        { text: '旗舰店(银星)', id: 3 },
      ],
      orderInfo: null,
      loading: false,
      finished: false,
      userList: [],
      pg: 0,
      ps: 10,
      defaultHeadUrl: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210625104311334.png'
    }
  },

  created() {
    let { id } = this.$route.params;
    this.orderId = id;
    this.getOrderInfo(id);
  },
  methods: {
    //获取订单详情
    getOrderInfo(orderId) {
      this.$toast.loading({ message: '加载中...', duration: 0, overlay: true })
      this.get("/OrderGoods/ExperienceOrder/AllotView?", { orderId }, 2).then(res => {
        this.$toast.clear();
        if (res.code === 1) {
          let { levellist, order, isallot, stateName, product } = res.response;
          order.stateName = stateName;
          this.orderInfo = order;
          this.columns = levellist;
          this.productList = product;
        }
      })
    },
    copyBtn() {
      this.$toast('复制成功')
    },
    onConfirm(item) {
      this.finished = false;
      this.chooseItem = item;
      this.showLevelPicker = false;
      this.onSearch();
    },
    onSearch(type) {
      if (type === 1 && !this.searchKey) return this.$toast("请输入输入手机号或者姓名");
      this.userList = [];
      this.pg = 0;
      this.finished = false;
      this.getUserList();
    },
    getUserList() {
      this.loading = true;
      this.pg++;
      this.get("/OrderGoods/ExperienceOrder/AllotAgentList?", {
        orderid: this.orderId,
        searchStr: this.searchKey,
        levelid: this.chooseItem ? this.chooseItem.id : '',
        pageIndex: this.pg,
        pageSize: this.ps
      }, 2).then(res => {
        this.loading = false;
        if (res.code === 1) {
          let list = res.response.agentList || [];
          if (list.length < this.ps) {
            this.finished = true;
          }
          this.userList = this.userList.concat(list);
        } else {
          this.finished = true;
        }
      })
    },
    //点击分单
    handleSplit(item) {
      this.$dialog.confirm({
        title: '提示',
        message: '是否确认将该订单分给Ta'
      }).then(() => {
        this.$toast.loading({ message: '分单中...', duration: 0, overlay: true });
        this.get("/OrderGoods/ExperienceOrder/Allot?", {
          orderId: this.orderId,
          agentId: item.id
        }, 2).then(res => {
          if (res.code === 1) {
            this.$toast("分单成功");
            //&tabId=7
            window.location.href = `${this.userHost}/rolorweb/html/order/lower.html?brandId=${this.orderInfo.brand_id}`
          } else {
            this.$toast.clear();
          }
        })
      }).catch(() => { })
    }
  }
}
</script>
<style lang='scss' scoped>
.van-cell-group {
  text-align: left;

  .van-cell {
    padding: 10px 15px;
  }

  .cus-title {
    flex: 2;
    color: #666666;
    text-align: left;
    font-size: 12px;
  }

  .cus-value {
    color: #999999;
    font-size: 12px;
  }

  .large-title {
    color: #333;
    font-weight: bold;
  }

  .field-cont {
    padding-top: 0;

    .van-cell__value {
      display: flex;
      align-items: center;

      .van-field {
        height: 32px;
        font-size: 12px;
        padding: 6px 16px;
        line-height: 20px;
        width: calc(100% - 60px);
        border-radius: 6px;
        margin-right: 10px;
        background-color: #F2F2F2;
      }

      .van-button {
        width: 50px;
        height: 32px;
        padding: 0;
        border-radius: 6px;
      }
    }
  }

  .filter-box {
    padding-top: 0;

    .van-cell__value {
      border: 1px solid #F1F1F1;
      display: flex;
      padding: 8px 15px;
      border-radius: 6px;
      align-items: center;
      font-size: 12px;
      color: #333;
      justify-content: space-between;

      span {
        color: #999;
      }
    }
  }

  .van-loading {
    padding: 10px 0;
    text-align: center;
  }

  .load-more {
    color: #FF98A0;
    font-size: 14px;
    padding: 10px 0;
    text-align: center;
  }

  .no-data {
    color: #999;
    font-size: 14px;
    padding: 10px 0;
    text-align: center;
  }
}

.product-list {
  .good-item {
    .van-cell__value {
      display: flex;
      font-size: 14px;
      justify-content: space-between;

      >img {
        width: 60px;
        height: 60px;
        margin-right: 10px;
      }

      .media-body {
        width: calc(100% - 110px);
        text-align: left;

        .good-name {
          color: #333;
          font-size: 14px;
          line-height: 16px;
          margin-bottom: 7px;
        }

        .van-tag {
          font-size: 10px;
        }
      }

      .count {
        width: 60px;
        color: #666666;
        text-align: right;
      }
    }
  }
}

.user-list {
  .user-item {
    line-height: 14px;
    font-size: 12px;

    >img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }

    .user-cont {
      width: calc(100% - 100px);
      padding-right: 10px;

      .user-info {
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        align-items: center;

        .name {
          flex: 1;
        }

        .phone {
          padding: 2px 4px;
          font-size: 10px;
          border: 1px solid #F1F1F1;
          border-radius: 8px;

          .van-tag {
            font-size: 10px;
            margin-left: 5px;
          }
        }
      }

      .level-name {
        color: #999;
        font-size: 10px;
      }
    }

    .van-button {
      width: 60px;
      height: 20px;
      padding: 0;
      font-size: 11px;
    }

    &:after {
      left: 55px;
    }
  }
}
</style>